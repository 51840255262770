<template>
  <l-tela titulo="Cadastro de Clientes">
    <l-form :cssColunas="4" :salvar="salvar">
      <i-basico
        ref="inputNomeEvento"
        id="nomeCliente"
        label="Nome evento"
        v-model="form.nomeEvento"
        :validations="{ required: true, min: 3 }"
        class="duas-colunas-ini"
      />
      
      <i-basico
        ref="inputNomeCliente"
        id="nomeCliente"
        label="Nome"
        v-model="form.nome"
        :validations="{ required: true, min: 3 }"
        class="duas-colunas-ini"
      />

      <i-contato
        id="contato"
        label="Contato"
        v-model="form.contato"
        :validations="{ required: true, validaLenCel: true }"
        class="duas-colunas-fim"
      />

      <i-basico
        id="documentoRG"
        label="RG"
        v-model="form.documentoRG"
        class="dois-colunas-ini"
      />

      <i-basico
        id="documento"
        label="CPF/CNPJ"
        v-model="form.documento"
        class="dois-colunas-ini"
      />

      <i-basico
        id="endereco"
        label="Endereço"
        v-model="form.endereco"
        class="endereco"
      />

      <i-basico
        id="numero"
        label="Número"
        type="number"
        v-model="form.numero"
        class="uma-coluna-fim"
      />

      <i-cidade
        id="cidade"
        label="Cidade"
        v-model="form.cidade"
        class="duas-colunas-ini"
      />

      <i-basico
        id="bairro"
        label="Bairro"
        v-model="form.bairro"
        class="duas-colunas-fim"
      />

      <i-basico
        id="endereco"
        label="Obs. endereço"
        v-model="form.observacaoEntrega"
        class="linha-toda"
      />

      <template v-slot:botoes>
        <v-btn @click="sair">Sair</v-btn>
        <v-btn color="primary" type="submit">Salvar</v-btn>
      </template>
    </l-form>
  </l-tela>
</template>

<script>
import clienteService from '@/services/cliente.service'
import LoadingMixin from '@/components/Mixins/Loading'
import NotificacaoMixin from '@/components/Mixins/Notificacao'

export default {
  mixins: [LoadingMixin, NotificacaoMixin],
  data() {
    return {
      form: {
        _id: null,
        nome: '',
        nomeEvento: '',
        contato: '',
        endereco: '',
        observacaoEntrega: '',
        numero: '',
        cidade: '',
        bairro: '',
        documento: '',
      },
    }
  },
  mounted() {
    const { clienteId } = this.$route.params
    if (clienteId) {
      this.carregar(clienteId)
    } else {
      this.$refs.inputNomeCliente.definirFoco()
    }
  },
  methods: {
    async carregar(clienteId) {
      this.mostrarLoading()
      try {
        this.form = await clienteService.buscaPorId(clienteId)
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    async salvar() {
      this.mostrarLoading()
      try {
        const { _id, nome, nomeEvento, contato, endereco, observacaoEntrega, numero, cidade, bairro, documento, documentoRG } =
          this.form
        if (_id) {
          await clienteService.atualizar({
            _id,
            nome,
            nomeEvento,
            contato,
            endereco,
            observacaoEntrega,
            numero,
            cidade,
            bairro,
            documento,
            documentoRG,
          })
        } else {
          await clienteService.cadastrar({
            nome,
            nomeEvento,
            contato,
            endereco,
            observacaoEntrega,
            numero,
            cidade,
            bairro,
            documento,
            documentoRG,
          })
          this.sair()
        }

        this.alertaDadosSalvos()
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    sair() {
      this.$router.push({ name: 'rota-clientes' })
    },
  },
}
</script>

<style lang="scss" scoped>
.duas-colunas-ini {
  grid-column: 1 / 3;
}

.tres-colunas-ini {
  grid-column: 1 / 4;
}

.uma-coluna-fim {
  grid-column: 4 / -1;
}

.duas-colunas-fim {
  grid-column: 3 / -1;
}

.endereco {
  grid-column: 1 / 4;
}

.linha-toda {
  grid-column: 1 / -1;
}
</style>
